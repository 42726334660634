import React from 'react'
import { Helmet } from 'react-helmet'

import Hero from '../components/Hero'
import Layout from '../components/Layout'
import SEO from '../components/SEO'

const ie8 = ''

export default () => {
  const [scriptReady, setScriptReady] = React.useState(false)
  React.useEffect(() => {
    const script =
      typeof document !== 'undefined' && document.createElement('script')
    script.src = '//js.hsforms.net/forms/v2.js'

    typeof document !== 'undefined' && document.body.appendChild(script)

    script.addEventListener('load', () => {
      if (window.hbspt) {
        window.hbspt.forms.create({
          portalId: '5442788',
          formId: '2fa6b754-20d3-490c-93f0-bf078c91f4cb',
          target: '#hubspotForm'
        })
      }
    })

    return () => {
      typeof document !== 'undefined' && document.body.removeChild(script)
    }
  }, [])

  return (
    <Layout>
      <Helmet>
        {`<!--[if lte IE 8]><script charset="utf-8" type="text/javascript" src="//js.hsforms.net/forms/v2-legacy.js"></script><![endif]-->`}
      </Helmet>
      <SEO title={`Support | Afwerx Space Challenge Virtual Showcase`} />
      <Hero
        title="Register"
        className="exhibitor-page"
        img={require('../assets/imgs/world.jpg')}
      />
      <main className="container intro-text text--center register-form">
        <div id="hubspotForm" />
      </main>
    </Layout>
  )
}
